<script lang="ts" setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { setCurrentViewContext } from "@/utils/GlobalState"
import * as siteServices from "@/services/Site"
import SiteAccountAffiliationEdit from "@/components/sites/SiteAccountAffiliationEdit.vue"
import AccountFindAndLink from "@/components/accounts/AccountFindAndLink.vue"
import ExpandCollapse from "@/components/ExpandCollapse.vue"
import PageLoading from "@/components/PageLoading.vue"

const route = useRoute()
const router = useRouter()
const siteCmiId = parseInt(route.params.siteCmiId)
const site = ref(null)
const active = ref("current")
const accountList = ref([])

async function getData() {
  site.value = await siteServices.getSiteByCmiId(siteCmiId)
  const canEdit = siteServices.canEditSite(site.value ?? { role: null })
  if (!site.value || !canEdit) {
    router.replace({ name: "SiteList" })
  }
  setCurrentViewContext(site.value, "site")
  await refreshAccountList()
}

function removeAccountFromList(accountId) {
  const index = accountList.value.findIndex((a) => a.accountId === accountId)
  accountList.value.splice(index, 1)
}

async function refreshAccountList() {
  accountList.value = await siteServices.getSiteAccountList(site.value)
}

async function searchFunction(token: string) {
  return await siteServices.searchAccountsToLink(siteCmiId, token)
}

getData()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Manage Accounts & Roles") }}
    </h1>

    <div
      v-if="site"
      class="tabs is-toggle is-toggle-rounded is-centered"
    >
      <ul>
        <li :class="{ 'is-active': active == 'current' }">
          <a @click="active = 'current'">
            {{ $gettext("Current Accounts") }}
          </a>
        </li>
        <li :class="{ 'is-active': active == 'new' }">
          <a @click="active = 'new'">
            {{ $gettext("Add an Account") }}
          </a>
        </li>
      </ul>
    </div>

    <template v-if="site && active == 'current'">
      <PageLoading
        v-if="!accountList.length"
        wrapper-classes="padded"
        :with-text="true"
      />
      <form v-else>
        <section class="block">
          <p class="muted is-italic">
            {{ $gettext("Accounts with a role at this site") }}
          </p>
          <ExpandCollapse>
            <template #label>
              {{ $gettext("What do the roles mean?") }}
            </template>
            <template #content>
              <div class="role-explainer columns">
                <div class="column">
                  <h3>{{ $gettext("Standard User") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Standard Users work at the site. They can do most common tasks within <em>Count Me In</em>.',
                        true,
                      )
                    "
                  />
                </div>
                <div class="column">
                  <h3>{{ $gettext("Site Adminstrator") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Site Administrators work at the site. They may do everything standard users can do. They also administer the site in <em>Count Me In</em>.',
                      )
                    "
                  />
                </div>
                <div class="column">
                  <h3>{{ $gettext("Site Supporter") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Site Supporters do not work at the site. They are usually trainers. They support the site in its use of <em>Count Me In</em>.',
                      )
                    "
                  />
                </div>
                <div class="column">
                  <h3>{{ $gettext("Site Observer") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Site Observers may only visit site reports. They are unable to access any child-level information, and cannot manage the site in <em>Count Me In</em>.',
                      )
                    "
                  />
                </div>
              </div>
            </template>
          </ExpandCollapse>
        </section>
        <section class="panel">
          <div
            v-for="account in accountList"
            :key="account.id"
            class="account block"
          >
            <ExpandCollapse>
              <template #label>
                <strong>{{ account.name }}</strong>
                <div class="muted">
                  {{ account.roleLabel }}
                </div>
              </template>
              <template #content>
                <div class="account-controls">
                  <div
                    v-if="account.editable"
                    class="block"
                  >
                    <router-link
                      class=""
                      :to="{
                        name: 'AccountEditForOther',
                        params: { siteCmiId: site.cmiId, accountCmiId: account.accountId },
                      }"
                    >
                      {{ $gettext("Edit account") }}
                    </router-link>
                    |
                    <router-link
                      class=""
                      :to="{
                        name: 'ChangeOthersPassword',
                        params: { siteCmiId: site.cmiId, accountCmiId: account.accountId },
                      }"
                    >
                      {{ $gettext("Change password") }}
                    </router-link>
                  </div>
                  <SiteAccountAffiliationEdit
                    :account="account"
                    @remove-account.once="removeAccountFromList"
                  />
                </div>
              </template>
            </ExpandCollapse>
          </div>
        </section>
      </form>
    </template>

    <template v-if="active == 'new'">
      <p
        v-if="site.canLinkAccountsToSite"
        class="context"
        v-html="
          $gettext(
            'To provide access to this site to someone who already has a <em>Count Me In</em> account, you need to link their account below. Otherwise, you may create a brand new account.',
          )
        "
      />
      <div
        class="columns"
        style="gap: 1em"
      >
        <div
          v-if="site.canLinkAccountsToSite"
          class="column box"
        >
          <AccountFindAndLink
            :site-cmi-id="siteCmiId"
            :search-function="searchFunction"
            @refresh-account-list="refreshAccountList"
          />
        </div>
        <div class="column box">
          <h3 class="subtitle mb-4">
            {{ $gettext("Add an account") }}
          </h3>
          <router-link
            :to="{ name: 'AccountCreateForSite', params: { siteCmiId } }"
            class="button is-primary"
          >
            {{ $gettext("Create new account") }}
          </router-link>
        </div>
      </div>
    </template>
    <section v-if="!site">
      <PageLoading :with-text="true" />
    </section>
  </article>
</template>

<style scoped>
.panel .icon-pencil {
  fill: white;
  max-width: 1em;
  margin-right: 0.5em;
}
.account-controls {
  padding: 1em;
}
section.block {
  padding: 0.5em 0.75em 0;
}
.role-explainer {
  font-size: 75%;
}
.role-explainer h3 {
  font-weight: bold;
}
/* The following borrowed from .panel-block */
.account.block {
  padding: 0.5em 0.75em;
}
.account.block:not(:last-child) {
  margin-bottom: 0.5rem;
}
.account.block:not(:last-child) {
  border-bottom: 1px solid hsl(0deg, 0%, 93%);
}
.account strong {
  display: inline-block;
  margin-right: 1em;
}
p.context {
  padding: 1em;
}
</style>
