<script setup>
import { ref, computed } from "vue"
import { computedAsync } from "@vueuse/core"
import { useRoute, useRouter } from "vue-router"
import dayjs from "dayjs"

import { gettext } from "@/utils/Translation"
import {
  getChildrenWithAssessmentsForMonth,
  updateChildrenFromServer,
} from "@/services/Child"
import { getSiteByCmiId } from "@/services/Site"
import {
  setCurrentViewContext,
  getLastUpdateInfo,
  areCachedDataStale,
  showFlashMessage,
  setStalenessNotificationTime,
} from "@/utils/GlobalState"
import { getCalendar, convertCalendarToWeeks } from "@/utils/Calendar"
import useServerRefresher from "@/composables/useServerRefresher"
import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
import LastSynced from "@/components/LastSynced.vue"
import ModalWindow from "@/components/ModalWindow.vue"
import AssessmentCalendarHelp from "@/components/AssessmentCalendarHelp.vue"
import AssessmentDay from "@/components/AssessmentDay.vue"

const { $gettext } = gettext
const route = useRoute()
const router = useRouter()
const siteCmiId = parseInt(route.params.siteCmiId)
const site = ref(null)
const showHelpModal = ref(false)
const showDayModal = ref(false)
const dayModalDate = ref(null)
const nextSiteAssessmentDate = ref(null)
const lastSynced = ref(undefined)
const currentMonth = ref(getDateFromQueryString())
const calendar = computed(() => getCalendar(currentMonth.value.year(), currentMonth.value.month()))
const children = computedAsync(async () => {
  return await getChildrenWithAssessmentsForMonth(siteCmiId, currentMonth.value)
}, [])
const assessments = computed(() => getAssessmentMapping())
const { refreshUnderway, serverRefresher } = useServerRefresher()
let staleNotificationQueryKey = ""

function openDayModal(date) {
  showDayModal.value = true
  dayModalDate.value = date
}

function getDateFromQueryString() {
  // m query param must be between 1 and 12. set month to current month (0 index) if month value is unexpected
  let month = parseInt(route.query["m"]) - 1 || ""
  if (month < 1 || month > 12) {
    month = dayjs().month()
  }
  // year must be between 2016 (probably the first year of data) and the next year. set year to current year if year value is unexpected
  let year = parseInt(route.query["y"]) || ""
  if (year < 2016 || year > dayjs().year() + 1) {
    year = dayjs().year()
  }
  return dayjs().year(year).month(month)
}

function incrementMonth() {
  currentMonth.value = currentMonth.value.add(1, "month")
  updateRouteQuery()
}
function decrementMonth() {
  currentMonth.value = currentMonth.value.subtract(1, "month")
  updateRouteQuery()
}
function updateRouteQuery() {
  router.replace({
    query: { m: currentMonth.value.month() + 1, y: currentMonth.value.year() },
  })
}

/**
 * Retrieves a mapping of date strings (e.g.: "2023-09-12") to a list of children who have assessments on those dates.
 * This function relies on the `currentMonth` and the `children`.
 * @return {Object <string, Array<Child>>} A mapping of date strings to an array of child objects.
 * return example:
 * {
 *   "2023-09-12": [
 *     { child object },
 *     { child object },
 *     { child object }
 *   ],
 *   "2023-09-15": [
 *     { child object }
 *   ]
 * }
 */
function getAssessmentMapping() {
  const dateAssessmentMap = {}
  const startOfMonth = currentMonth.value.startOf("month")
  const endOfMonth = currentMonth.value.endOf("month")
  children.value.forEach((child) => {
    const {
      nextAnemiaAssessmentDate,
      nextEarlyidAssessmentDate,
      nextGrowthAssessmentDate,
      nextMealtimeAssessmentDate,
      nextBestPracticesAssessmentDate,
    } = child
    ;[
      nextAnemiaAssessmentDate,
      nextEarlyidAssessmentDate,
      nextGrowthAssessmentDate,
      nextMealtimeAssessmentDate,
      nextBestPracticesAssessmentDate,
    ].forEach((assessment) => {
      if (!assessment) return
      const assessmentDate = dayjs(assessment)
      if (assessmentDate.isBefore(startOfMonth) || assessmentDate.isAfter(endOfMonth)) return
      const formattedDate = assessmentDate.format("YYYY-MM-DD")
      if (!dateAssessmentMap[formattedDate]) {
        dateAssessmentMap[formattedDate] = []
      }
      const isChildInArray = dateAssessmentMap[formattedDate].some((c) => c.id === child.id)
      if (!isChildInArray) {
        dateAssessmentMap[formattedDate].push(child)
      }
    })
  })
  return dateAssessmentMap
}

async function getChildrenFromServer(wasManuallyTriggered = false) {
  await serverRefresher(updateChildrenFromServer, [siteCmiId], staleNotificationQueryKey, wasManuallyTriggered)
    .catch(error => {
      if (error.name === "CONNECTIVITY_REQUIRED") {
        const msg = $gettext("You do not appear to be online.")
        let details = ""
        if (lastSynced.value) {
          details = $gettext("Assessment due dates for your site were last updated %{ date }.", { date: lastSynced.value })
        }
        else {
          details = $gettext("Assessment due dates for your site have never been updated from the server.")
        }
        showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
        setStalenessNotificationTime(staleNotificationQueryKey)
      }
      else {
        throw error
      }
    })
  children.value = await getChildrenWithAssessmentsForMonth(siteCmiId, currentMonth.value)
  lastSynced.value = await getLastUpdateInfo({ type: "siteChildren", localItemId: site.value.id })
}

async function getData() {
  site.value = await getSiteByCmiId(siteCmiId)
  if (!site.value) {
    router.replace({ name: "SiteList" })
  }
  staleNotificationQueryKey = `siteChildren-${site.value.id}`
  setCurrentViewContext(site.value, "site")
  if (site.value && site.value.isSiteMealtimeBestPracticeAssessmentEnabled) {
    nextSiteAssessmentDate.value = dayjs(site.value.nextSiteAssessmentDate)
  }
  if (await areCachedDataStale({ type: "siteChildren", localItemId: site.value.id })) {
    getChildrenFromServer()
  }
  else {
    lastSynced.value = await getLastUpdateInfo({ type: "siteChildren", localItemId: site.value.id })
  }
}

getData()
</script>

<template>
  <article>
    <PageTitleWithRefresh
      :title="$gettext('Assessment Calendar')"
      :refresh-function="getChildrenFromServer"
      :refresh-underway="refreshUnderway"
    />
    <ModalWindow
      :show-modal="showHelpModal"
      :close-modal-function="() => showHelpModal = false"
    >
      <AssessmentCalendarHelp />
    </ModalWindow>
    <ModalWindow
      :show-modal="showDayModal"
      :close-modal-function="() => showDayModal = false"
    >
      <AssessmentDay
        :site="siteCmiId"
        :date="dayModalDate"
        :assessments="assessments"
        :add-site-assessment="nextSiteAssessmentDate && nextSiteAssessmentDate.isSame(dayModalDate, 'day')"
      />
    </ModalWindow>
    <div class="page-help-link">
      <a
        aria-controls="page-help-modal"
        aria-haspopup="true"
        tabindex="0"
        @click="() => showHelpModal = true"
      >
        {{ $gettext("Help with page") }}<img src="/src/assets/icon-question-mark.svg" />
      </a>
    </div>
    <table class="calendar">
      <thead>
        <tr>
          <th>
            <a
              class="prev arrow"
              @click="decrementMonth"
            />
          </th>
          <th
            class="month-col"
            colspan="5"
          >
            {{ currentMonth.format("MMMM") }} {{ currentMonth.year() }}
          </th>
          <th>
            <a
              class="next arrow"
              @click="incrementMonth"
            />
          </th>
        </tr>
        <tr class="day-of-week-label">
          <th class="weekday">
            {{ $gettext("Mo") }}
          </th>
          <th class="weekday">
            {{ $gettext("Tu") }}
          </th>
          <th class="weekday">
            {{ $gettext("We") }}
          </th>
          <th class="weekday">
            {{ $gettext("Th") }}
          </th>
          <th class="weekday">
            {{ $gettext("Fr") }}
          </th>
          <th class="weekend">
            {{ $gettext("Sa") }}
          </th>
          <th class="weekend">
            {{ $gettext("Su") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="week in convertCalendarToWeeks(calendar)"
          :key="week[0].format()"
        >
          <td
            v-for="d in week"
            :key="d.day"
            :class="[
              [0, 6].includes(d.day()) ? 'weekend' : 'weekday',
              d.isSame(dayjs(), 'day') ? 'today' : '',
            ]"
          >
            <div
              class="day"
              :class="d.month() !== currentMonth.month() ? 'other-month' : ''"
            >
              <span class="day-number">{{ d.date() }}</span>
              <a
                v-if="Object.keys(assessments).includes(d.format('YYYY-MM-DD'))"
                @click="openDayModal(d)"
              >
                <span class="secondary badge">
                  {{
                    assessments[d.format("YYYY-MM-DD")].length +
                      // add 1 if this is the nextSiteAssessmentDate
                      (nextSiteAssessmentDate && nextSiteAssessmentDate.isSame(d, "day") ? 1 : 0)
                  }}
                </span>
              </a>
              <span v-else>&nbsp;</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <LastSynced :last-synced="lastSynced" />
  </article>
</template>
